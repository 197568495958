import * as React from "react"
import { navigate } from 'gatsby-link';
import styled from '@emotion/styled'
import Layout from '../components/Layout/Layout';
import { 
  PageTitleHeader,
  Breakpoints,
  blissfulColour,
  FormInputBlock,
  InputFieldset,
  FormInputText,
  FormSelect,
  FormTextAreaBlock,
  FormInputRadio,
  FormTextArea,
  FormButton,
  RequiredAsterisk
} from '../globalStyles';

const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;
`

const Contact = () => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""

  const updateSubject = (e) => {
    document.getElementById("email-subject").setAttribute('value', "Cake inquiry form for " + e.target.value);
  }

  return(
  <Layout
    title="Contact Us"
    description="Please complete the contact form and provide as much detail as possible. I’m looking forward to making a beautiful cake that’s unique and customized to your liking for your big day!"
    pathname={pathname}
  >
    <Container>
      <PageTitleHeader> Contact Blissful Crumbs </PageTitleHeader>

      <p>
        Hello and thank you for your interest. Please complete the contact form below and provide as much detail as possible. I’m looking forward to making a beautiful cake that’s unique and customized to your liking for your big day!
      </p>

      <form name="contact" encType="multipart/form-data" action="/contact/thanks" method="POST" data-netlify="true">
      
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="subject" value="Cake inquiry form" id="email-subject"/>

        <FormInputBlock>
          <InputFieldset>
            <legend> Name<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="text" name="name" required onChange={updateSubject}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend> Email<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="email" name="email" required/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend> Event Venue (if delivery is required) </legend>
            <FormInputText type="text" name="event_venue" />
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend> Event Date<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="text" name="event_date" required/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend> Approximate Budget (view price ranges <a href="/prices" target="_blank">here</a>)<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="number" name="budget" required/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend> Message<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormTextAreaBlock>
              <FormTextArea name="message" rows="4" cols="50" required/>
            </FormTextAreaBlock>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend> How did you hear about us?<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="text" name="How did you hear about us?"/>
          </InputFieldset>
        </FormInputBlock>

        <FormButton type="submit"> Submit </FormButton>

      </form>

    </Container>
  
  </Layout>
  )
}

export default Contact

